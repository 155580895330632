import React from "react"

import { Header, Landing } from "../components"

import SEO from "./SEO"

// store
import useStore from "../store"

export default function Home() {
  const current = useStore(state => state.current)

  return (
    <div>
      <SEO />
      <Header />
      <Landing content={current.home} />
    </div>
  )
}
